import React from 'react';
import Image from 'next/image';
import SectionHeading from '../Common/SectionHeading';

const Usp = ({title=null}) => {
    return (
        <>
            <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:py-8 py-4">
                <div className="grid grid-cols-12 gap-4">
                    <div className="lg:col-span-5 col-span-12">
                        <p className='mb-0'>Our USPs</p>
                        <SectionHeading title={title}  customClass="text-left text-black"/>
                        <p className="text-base">
                            In business, we need data and insights to make informed decisions; DelveInsight
                            provides the most actionable market data in the industry. We aim to deliver this data to
                            a wider audience through our platform.
                        </p>
                        <p className="text-base">
                            The cloud-based interactive platform provides insights in PDF and Excel format in a
                            single place, along with the ease of sharing data with co-workers. Therapeutic and
                            indication-based categorization helps get the information that has been grouped and
                            tailored to suit the research requirements of the clients.
                        </p>
                        <p className="text-base">The added add-on regarding analyst support equips the ease of customizations at hand, an all-in-one interactive data analysis platform.</p>
                    </div>
                    <div className="lg:col-span-7 col-span-12 mx-auto">
                        <div id="Usp" className='hidden lg:block'>
                            <div className="pdelve-feature-circle wow zoomIn postion-relative mt-5">
                                <div className="pdelve-img">
                                    <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Data-Visualization.png'}
                                                    alt="image0"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Data Visualization</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Optimistically-Secured.png'}
                                                    alt="image1"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Optimistically Secured</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Ease-of-sharing.png'}
                                                    alt="image2"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Ease of Sharing</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Customizable-Dashboard.png'}
                                                    alt="image3"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Customizable Dashboard</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Rich-features.png'}
                                                    alt="image4"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Rich Features</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Tech-driven.png'}
                                                    alt="image5"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Tech Driven</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/cost-saving.png'}
                                                    alt="image6"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Cost Saving</h6>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="feature-info">
                                            <div className="feature-img">
                                                <Image 
                                                    priority={false} 
                                                    width={2000} 
                                                    height={2000}
                                                    className="img-fluid client-img"
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/Instant-access.png'}
                                                    alt="image7"
                                                />
                                            </div>
                                            <h6 className="text-[#1b5df3]">Instant Access</h6>
                                        </div>
                                    </li>
                                    </ul>
                                    <div className="dot-circle">
                                        <div className="effect-circle"></div>
                                    </div>
                                    <div className="main-circle">
                                            <div className="circle-bg">
                                                <Image 
                                                    priority={false} 
                                                    width={100}
                                                    height={100} 
                                                    src={process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/app/6-9.png'}
                                                    alt="image" 
                                                    style={{margin: 'auto', paddingTop: '15px'}}
                                                />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Usp;
